@import "src/styles/shared";

.row {
  td {
    border: 0;

    &.error {
      color: $color-functional-error-red;
    }

    &.info {
      color: $color-discovery-text-onlight;
    }

    &.success {
      color: $color-functional-success-green;
    }
  }
}

.collapseRow {
  background-color: $color-neutral-grey-8;

  .collapseCell {
    padding: 0;
  }
}

.innerTable {
  margin: 0;
}
