@import "src/styles/settings";

.header {
  align-items: center;
  box-shadow: 0 4px 4px rgb(0 0 0 / 5%);
  display: flex;
  flex-shrink: 0;
  height: 68px;
  justify-content: space-between;
  padding: 0 24px;
  z-index: 10;
}
