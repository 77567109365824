@import "settings";
@import "@sindeo/style-guide/sass/00-manifests/04-elements.manifest";
@import "@sindeo/style-guide/sass/00-manifests/05-objects.manifest";
@import "@sindeo/style-guide/sass/00-manifests/07-utilities.manifest";

.ReactModal__Overlay {
  z-index: 10;
}

#root {
  font-family: "DM Sans", sans-serif;
  font-size: 16px;

  @include mq-min($breakpoint-3) {
    display: flex;
    flex-direction: column;
  }
}

table,
p,
span,
th,
td,
a {
  font-family: "DM Sans", sans-serif !important;
  font-size: 15px;
}
