.pagination {
  p {
    margin: 0;
  }
}

.message {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
}
