@import "src/styles/shared";

/* header + filter button + paddings */
$page-content-height: 68px + 43px + 80px + 16px;

.tableContainer {
  height: auto;
  max-height: calc(100vh - $page-content-height);
}

.reevaluationsTable {
  @extend .table;
}

.pagination {
  background-color: white;
  bottom: 0;
  position: sticky;

  p {
    margin: 0;
  }
}
