@import "styles/settings";

.workingHours {
  margin-top: 30px;
}

.workingHours_title {
  font-weight: 600;
  margin-bottom: 0;
  padding: 10px 0;
}

.workingHours_addBtn {
  align-items: center;
  color: #417690;
  cursor: pointer;
  display: flex;
  font-weight: 400;
  margin: 20px 0;
}

.workingHours_withShortcuts {
  align-items: center;
  display: flex;

  @include mq-max($breakpoint-2) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.workingHours_table {
  @include mq-max($breakpoint-3) {
    table,
    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    tr {
      border-bottom: 2px solid #eee;
    }

    thead tr {
      left: -9999px;
      position: absolute;
      top: -9999px;
    }

    td {
      border: none;
      border-bottom: 1px solid #eee;
      padding-left: 50%;
      position: relative;
    }

    td::before {
      font-weight: 600;
      left: 6px;
      padding-right: 10px;
      position: absolute;
      text-transform: uppercase;
      top: 6px;
      white-space: nowrap;
      width: 45%;
    }

    td:nth-of-type(1)::before {
      content: "Weekday";
    }

    td:nth-of-type(2)::before {
      content: "From Time";
    }

    td:nth-of-type(3)::before {
      content: "To Time";
    }

    td:nth-of-type(4)::before {
      content: "Delete?";
    }
  }
}
