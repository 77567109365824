@import "styles/settings";

.breadcrumbs {
  padding: 10px 40px;
}

.link,
.link:visited {
  color: $color-primary-400;
  font-weight: 600;
}

.last,
.last:visited {
  color: $color-primary-900;
}
