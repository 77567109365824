@import "styles/settings";

.form_fields {
  display: grid;
  grid-template-columns: 1fr;
  margin-bottom: 20px;
}

.form_field {
  margin-bottom: 20px;

  & > div {
    width: 100%;

    @include mq-min($breakpoint-3) {
      width: 35%;
    }
  }

  @include mq-min($breakpoint-3) {
    margin-right: 20px;
  }
}

.form_submit {
  margin-right: 10px;
}
