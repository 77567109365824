@import "src/styles/settings";

.container {
  background-color: $color-primary-100;
  height: calc(100vh - 68px);
  min-height: 100%;
  padding-top: 30px;
}

.list {
  list-style-type: none;
  padding-left: 0;

  a {
    align-items: center;
    color: $color-primary-900;
    display: flex;
    padding: 10px 30px;
    transition: none;
  }

  svg {
    margin-right: 5px;
  }

  a:visited {
    color: $color-primary-900;
  }
}

.link {
  margin-bottom: 10px;
}

.linkActive {
  background-color: $color-primary;
  color: $color-white !important;
  margin-bottom: 10px;

  & > span {
    font-weight: 600;
  }
}
