.filterDrawer {
  padding: 16px;
}

.textField {
  width: 400px;
}

.heading {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
